let importMaps = {};



var convertType = function(pType){
    switch(pType){
        case 'int':
        case 'decimal':
        case 'float':
        case 'real':
        case 'bigint':
        case 'smallint':
        case 'tinyint':
            return 'number';
        case 'datetime2':
            return 'datetime';
        case 'nvarchar':
        case 'nchar':
        case 'char':
        case 'varchar':
            return 'string';
        default:
            return pType
    }
}

function getImportMapNode() {
    return document.querySelector('script[type="importmap"]') ?? document.querySelector('script[type="importmap-shim"]')
}


var getLibUrl = (pLib)=>{
    let filePath = null;
    if (pLib.includes('/')) {
        filePath = pLib.split('/').slice(1).join('/');
        pLib = pLib.split('/')[0] + '/';
    }
    if(Object.keys(importMaps).length == 0){
        try{
            importMaps = JSON.parse(getImportMapNode()?.text);
        }catch(ex){
            console.warn("Failed to parse import maps. ",ex);
        }
    }

    if(importMaps && importMaps.imports && importMaps.imports.hasOwnProperty(pLib)){
        pLib = importMaps.imports[pLib];
    }
    if (filePath) {
        pLib = pLib + filePath;
    }

    return pLib;
}

var loadStyle = function(pCssUrl){
        
    let head = document.head;
    let link = document.createElement("link");
    let vHref = null;

    vHref = getLibUrl(pCssUrl);
    
    if(document.querySelector("[href='"+vHref+"']")) return;

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = vHref;
    head.appendChild(link);

}
var loadCdnStyle = function(pCssUrl){
    
    let head = document.head;
    let link = document.createElement("link");
    let vHref = getLibUrl(pCssUrl);


    
    if(document.querySelector("[href='"+vHref+"']")) return;

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = vHref;
    head.appendChild(link);

}
var loadCdnScript = async function(pScriptPath, pSetRaw = false){
    
    let scriptEle = document.createElement("script");

    scriptEle.setAttribute("src", pSetRaw ? pScriptPath : cdnBaseUrl+pScriptPath);
    scriptEle.setAttribute("type", "text/javascript");

    document.body.appendChild(scriptEle);
    let resolve = () => {};
    const promise = new Promise((res) => {resolve =res});
    scriptEle.addEventListener("load", () => {
        resolve();
    });
    return promise;
}

var getTemplate = (pLib)=>{
    return fetch(getLibUrl(pLib))
    .then(response => response.text())
    .then(data => {
        return data;
    });
}

const cdnBaseUrl = (document.querySelector('meta[name="o365-cdn-base-url"]'))?.content;



export {convertType, getTemplate, loadStyle,loadCdnStyle, getLibUrl,cdnBaseUrl,loadCdnScript};
